import $ from 'jquery';
import _ from 'underscore';
import PusherClass from 'pusher-js';
import tectoastr from 'tectoastr';

if (typeof pusherOptions !== 'undefined') {
	/* eslint-disable-next-line no-undef */
	let options = pusherOptions.clarify();
	let pusher = new PusherClass(options.appKey, options.options);
	window.pusher = pusher;

	let tokenExists = (token) => typeof token !== 'undefined' && $('.' + token).length;

	let refreshIfExists = function (token) {
		if (tokenExists(token)) {
			var url = window.location.pathname + window.location.search;
			$.pjax({ url: url, container: '#pjaxContainer', method: 'GET' });
		}
	};

	let refreshIfUrl = function (url, reload) {
		if (window.location.pathname === url) {
			let currentUrl = window.location.pathname + window.location.search;
			if (!reload) {
				$.pjax({ url: currentUrl, container: '#pjaxContainer', method: 'GET' });
			} else {
				window.location.href = currentUrl;
			}
		}
	};

	let hideIfExists = function (hideElement, hideIfExists) {
		if (tokenExists(hideElement) && tokenExists(hideIfExists)) {
			$('.' + hideElement).fadeOut();
		}
	};

	_.each(options.channels, function (channelName) {
		let channel = pusher.subscribe(channelName);

		channel.bind('message.simple', function (data) {
			tectoastr.notify(data.type, data.message);

			// Full pjax refresh
			refreshIfExists(data.refreshIfExists);

			// Hide element if present
			hideIfExists(data.hideElement, data.hideIfExists);

			// Trigger 'file.transcoding' event
			if (_.has(data, 'data') && _.has(data.data, 'transcodedFiles')) {
				$(document).trigger('file.transcoding', data);
			}
		});

		channel.bind('file.processed', (data) => $(document).trigger('file.processed', data));

		channel.bind('theme.updated', function (data) {
			if (data.message) {
				tectoastr.notify('info', data.message);
			}

			$.pjax.defaults.version = 'themeUpdated';

			setTimeout(() => {
				refreshIfUrl('/theme', true);
			}, 1000);
		});

		channel.bind('roles.revoked', function (data) {
			if (data.message) {
				tectoastr.notify('info', data.message);
			}

			refreshIfUrl('/user');
		});

		channel.bind('leaderboard.sync', function (data) {
			if (data.message) {
				tectoastr.notify('info', data.message);
			}

			if (data.status === 'finished') {
				if (tokenExists(data.refreshToken)) {
					refreshIfUrl('/judging/leaderboard', false);
				}
			} else if (tokenExists(data.refreshToken)) {
				var recalculating = data.type === 'recalculate' || !$('.leaderboard-recalculate').hasClass('hidden');
				$('.leaderboard-recalculate').toggleClass('hidden', !recalculating);
				$('.leaderboard-sync').toggleClass('hidden', recalculating);
			}
		});

		channel.bind('plagiarism.resources_found', function (data) {
			let actions = $('#full-scan-actions');

			if (data.error) {
				actions.html(data.error);
			} else {
				actions.html(data.actions);
			}
		});

		channel.bind('field_values.recalculation_completed', function (data) {
			if (data.message) {
				tectoastr.notify('info', data.message);
			}

			$(`.hide-on-field-${data.slug}`).addClass('hidden');
			$(`.show-on-field-${data.slug}`).removeClass('hidden');
			$(`.enable-on-field-${data.slug}`).prop('disabled', false);
			$(`.disable-on-field-${data.slug}`).prop('disabled', true);
		});

		channel.bind('duplicate_entries.scan_completed', function (data) {
			refreshIfExists(data.refreshIfExists);
		});

		channel.bind('review_stage.initiated', function (data) {
			tectoastr.notify('info', data.message);
			hideIfExists(data.hideElement, data.hideIfExists);
		});

		channel.bind('download.ready', function (data) {
			tectoastr.notify('info', data.message);
			hideIfExists(data.hideElement, data.hideIfExists);
		});

		channel.bind('form.copied', function (data) {
			tectoastr.notify('info', data.message);
			refreshIfExists(data.refreshIfExists);
		});

		channel.bind('documents.created', function (data) {
			tectoastr.notify('info', data.message);
			hideIfExists(data.refreshIfExists);
		});

		channel.bind('file.transcription', (data) => {
			tectoastr.notify(data.type, data.message);
			$(document).trigger('file.transcription', data);
		});
	});
}
